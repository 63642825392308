<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="3">
        <v-text-field outlined dense label="Code" v-model="record.Code"></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field
          outlined
          dense
          label="Description"
          v-model="record.Name"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-tabs background-color="accent" color="white">
        <v-tab>Defaults</v-tab>
        <v-tab>Dimensions</v-tab>
        <!--<v-tab>Data Source</v-tab>-->
        <v-tab>Data</v-tab>
        <v-tab>General</v-tab>
        <!-- <v-tab>Display</v-tab>
        <v-tab>Print</v-tab>
        <v-tab>Credit Cards</v-tab>  -->

        <v-tab-item class="mt-4">
          <v-row>
            <v-col cols="3">
              <v-autocomplete
                outlined
                dense
                v-model="record.SalePerson"
                :items="salesEmployees"
                item-text="SlpName"
                item-value="SlpCode"
                label="Sales Employee"
              ></v-autocomplete>
            </v-col>

            <v-col cols="3">
              <v-autocomplete
                outlined
                dense
                v-model="record.BPLId"
                :items="branches"
                :item-text="(item) => item.BPLId + '  -  ' + item.BPLName"
                item-value="BPLId"
                label="Branches"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                outlined
                dense
                v-model="record.DftItmsGrpCod"
                :items="itemGroups"
                item-text="ItmsGrpNam"
                item-value="id"
                label="Item Group"
              ></v-autocomplete>
            </v-col>

            <v-col cols="3">
              <v-autocomplete
                outlined
                dense
                v-model="record.Warehouse"
                :items="warehouses"
                :item-text="(item) => item.WhsCode + '  -  ' + item.WhsName"
                item-value="id"
                label="Warehouse"
                @change="handleWarehouseChange"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                outlined
                dense
                v-model="record.Driver"
                :items="drivers"
                item-text="RlpName"
                item-value="id"
                label="Driver"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                outlined
                dense
                label="Edit Item Price?"
                v-model="record.EdtItmPrc"
              ></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                outlined
                dense
                label="Sell From Bin?"
                v-model="record.SellFromBin"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" v-if="record.SellFromBin">
              <v-autocomplete
                v-if="this.showBinLocations"
                outlined
                dense
                v-model="record.DftBinLoc"
                :items="binlocations"
                item-text="BinCode"
                item-value="id"
                label="Bin Locations"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                outlined
                dense
                label="Clock in?"
                v-model="record.ClockIn"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" v-if="record.ClockIn">
              <v-autocomplete
                outlined
                dense
                v-model="record.EtstCode"
                :items="TimeSheets"
                item-text="Name"
                item-value="id"
                label="Timesheet"
              ></v-autocomplete>
            </v-col>

            <v-col cols="3">
              <v-checkbox
                outlined
                dense
                label="Activate Route?"
                v-model="record.RouteActive"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" v-if="record.RouteActive">
              <v-autocomplete
                outlined
                dense
                v-model="record.RouteID"
                :items="routes"
                item-text="Name"
                item-value="id"
                label="Default Route"
              ></v-autocomplete>
            </v-col>

            <v-col cols="3">
              <v-checkbox
                outlined
                dense
                label="Gps Active?"
                v-model="record.GpsActive"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" v-if="record.GpsActive">
              <v-autocomplete
                outlined
                dense
                v-model="record.GpsSetUpID"
                :items="GpsSetUp"
                item-text="Name"
                item-value="id"
                label="Gps Setup"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-tab-item>

        <v-tab-item>
          <v-row>
            <v-col cols="4" v-if="distributionRules1.length > 0">
              <v-autocomplete
                outlined
                dense
                v-model="record.CogsOcrCod"
                :items="distributionRules1"
                :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                item-value="OcrCode"
                :label="Dimension1"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" v-if="distributionRules2.length > 0">
              <v-autocomplete
                outlined
                dense
                v-model="record.CogsOcrCo2"
                :items="distributionRules2"
                :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                item-value="OcrCode"
                :label="Dimension2"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" v-if="distributionRules3.length > 0">
              <v-autocomplete
                outlined
                dense
                v-model="record.CogsOcrCo3"
                :items="distributionRules3"
                :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                item-value="OcrCode"
                :label="Dimension3"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" v-if="distributionRules4.length > 0">
              <v-autocomplete
                outlined
                dense
                v-model="record.CogsOcrCo4"
                :items="distributionRules4"
                :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                item-value="OcrCode"
                :label="Dimension4"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" v-if="distributionRules5.length > 0">
              <v-autocomplete
                outlined
                dense
                v-model="record.CogsOcrCo5"
                :items="distributionRules5"
                :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                item-value="OcrCode"
                :label="Dimension5"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row class="pa-3">
            <v-col cols="12">
              <v-col cols="6">
                <v-checkbox
                  outlined
                  dense
                  v-model="postToLocal"
                  label="Post Data Locally"
                ></v-checkbox>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  outlined
                  dense
                  v-model="fetchFromLocal"
                  label="Fetch Data Locally"
                ></v-checkbox>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  outlined
                  dense
                  v-model="searchLocal"
                  label="Search Data Locally"
                ></v-checkbox>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  label="URL"
                  v-if="postToLocal || fetchFromLocal || searchLocal"
                  v-model="record.localUrl"
                ></v-text-field>
              </v-col>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row class="pa-3">
            <v-col cols="6">
              <v-checkbox
                outlined
                dense
                v-model="record.AddToFavourites"
                label="Add To Favourites"
              ></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                outlined
                dense
                v-model="record.MultiLogin"
                label="Allow MultiLogin"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    record: {},
    drivers: [],
    loader: false,
    priceLists: [],
    warehouses: [],
    salesEmployees: [],
    itemGroups: [],
    branches: [],
    Dimension1: "",
    Dimension2: "",
    Dimension3: "",
    Dimension4: "",
    Dimension5: "",

    distributionRules1: [],
    distributionRules2: [],
    distributionRules3: [],
    distributionRules4: [],
    distributionRules5: [],
    postToLocal: false,
    fetchFromLocal: false,
    searchLocal: false,
    showBinLocations: false,
    binlocations: [],
    TimeSheets: [],
    routes: [],
    GpsSetUp: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.searchLocal = val.useLocalSearch;
      this.postToLocal = val.postToLocal;
      this.fetchFromLocal = val.fetchFromLocal;
      this.record = { ...val };

      console.log(this.record);
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.loader = true;
      this.record.postToLocal = this.postToLocal;
      this.record.fetchFromLocal = this.fetchFromLocal;
      this.record.useLocalSearch = this.searchLocal;
      this.$emit("data", this.record);
    },
    getRoutes() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/routes_planning`)
        .then((res) => {
          self.routes = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getWarehouses() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/warehouse`)
        .then((res) => {
          self.warehouses = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getEmployees() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee`)
        .then((res) => {
          self.salesEmployees = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getDrivers() {
      const self = this;
      this.$store
        .dispatch("get", `/drivers`)
        .then((res) => {
          self.drivers = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getItemGroups() {
      const self = this;
      this.$store
        .dispatch("get", `/itemgroup`)
        .then((res) => {
          self.itemGroups = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    handleWarehouseChange() {
      if (this.warehouses && this.record.Warehouse) {
        let data = this.warehouses.find((obj) => obj.id === this.record.Warehouse);

        if (data && data.BinActivat === "1") {
          this.showBinLocations = true;
          this.binlocations = data.binlocations;
        } else {
          this.showBinLocations = false;
        }
      }
    },
    getBranches() {
      const self = this;
      this.$store
        .dispatch("get", `/branches`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.branches = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getTimeSheets() {
      const self = this;
      this.$store
        .dispatch("get", `/timesheets-master-data`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.TimeSheets = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getDistributionRules(id) {
      const self = this;
      this.$store
        .dispatch("get", `/distribution-rules?DimCode=${id}`)
        .then((res) => {
          if (id == 1) {
            self.distributionRules1 = res.ResponseData;
            self.Dimension1 = res.ResponseData[0].odim.DimDesc;
          }
          if (id == 2) {
            self.distributionRules2 = res.ResponseData;
            self.Dimension2 = res.ResponseData[0].odim.DimDesc;
          }
          if (id == 3) {
            self.distributionRules3 = res.ResponseData;
            self.Dimension3 = res.ResponseData[0].odim.DimDesc;
          }
          if (id == 4) {
            self.distributionRules4 = res.ResponseData;
            self.Dimension4 = res.ResponseData[0].odim.DimDesc;
          }
          if (id == 5) {
            self.distributionRules5 = res.ResponseData;
            self.Dimension5 = res.ResponseData[0].odim.DimDesc;
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getGpsSetups() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/gps-setup`)
        .then((res) => {
          self.GpsSetUp = res.ResponseData;
          console.log(res, "res");
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getWarehouses();
    this.getGpsSetups();
    this.getDrivers();
    this.getEmployees();
    this.getItemGroups();
    this.getBranches();
    this.getDistributionRules(1);
    this.getDistributionRules(2);
    this.getDistributionRules(3);
    this.getDistributionRules(4);
    this.getDistributionRules(5);
    this.getTimeSheets();
    this.getRoutes();
  },
  updated() {
    this.handleWarehouseChange();
  },
};
</script>
